<template>
  <g>
    <g id="g515" clip-path="url(#clipPath519)"
      ><g id="g521" transform="rotate(-90,4307.5,2369)"
        ><path
          d="M 0,-154.5 C 85.328,-154.5 154.5,-85.328 154.5,0 154.5,85.328 85.328,154.5 0,154.5 -85.328,154.5 -154.5,85.328 -154.5,0 -154.5,-85.328 -85.328,-154.5 0,-154.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path523"/></g
      ><g id="g525" transform="rotate(-90,7521,-1449.5)"
        ><path
          d="M 0,-154.5 C 85.328,-154.5 154.5,-85.328 154.5,0 154.5,85.328 85.328,154.5 0,154.5 -85.328,154.5 -154.5,85.328 -154.5,0 -154.5,-85.328 -85.328,-154.5 0,-154.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path527"/></g
      ><g id="g529" transform="rotate(-90,5971,2973.5)"
        ><path
          d="M 0,-77.5 C 42.80207,-77.5 77.5,-42.80207 77.5,0 77.5,42.80207 42.80207,77.5 0,77.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path531"/></g
      ><g id="g533" transform="rotate(-90,8428,516.5)"
        ><path
          d="M 0,-77.5 C 42.80207,-77.5 77.5,-42.80207 77.5,0 77.5,42.80207 42.80207,77.5 0,77.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path535"/></g
      ><g id="g537" transform="rotate(-90,7672,-1298.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path539"/></g
      ><g id="g541" transform="rotate(-90,4156,2217.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path543"/></g
      ><g id="g545" transform="rotate(-90,3343.5,1405)"
        ><path
          d="M 0,-77.5 C 42.80207,-77.5 77.5,-42.80207 77.5,0 77.5,42.80207 42.80207,77.5 0,77.5 -42.80207,77.5 -77.5,42.80207 -77.5,0 -77.5,-42.80207 -42.80207,-77.5 0,-77.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path547"/></g
      ><g id="g549" transform="rotate(-90,6859.5,-2111)"
        ><path
          d="M 0,-77.5 C 42.80207,-77.5 77.5,-42.80207 77.5,0 77.5,42.80207 42.80207,77.5 0,77.5 -42.80207,77.5 -77.5,42.80207 -77.5,0 -77.5,-42.80207 -42.80207,-77.5 0,-77.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path551"/></g
      ><g id="g553" transform="rotate(-90,4969,3030.5)"
        ><path
          d="M 0,-77.5 C 42.80207,-77.5 77.5,-42.80207 77.5,0 77.5,42.80207 42.80207,77.5 0,77.5 -42.80207,77.5 -77.5,42.80207 -77.5,0 -77.5,-42.80207 -42.80207,-77.5 0,-77.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path555"/></g
      ><g id="g557" transform="rotate(-90,8485,-485.5)"
        ><path
          d="M 0,-77.5 C 42.80207,-77.5 77.5,-42.80207 77.5,0 77.5,42.80207 42.80207,77.5 0,77.5 -42.80207,77.5 -77.5,42.80207 -77.5,0 -77.5,-42.80207 -42.80207,-77.5 0,-77.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path559"/></g
      ><path
        d="M 1711,4370 H 9197"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path561"/><g id="g563" transform="rotate(90,2357,6840.5)"
        ><path
          d="m -113.5,0 c 0,-62.68432 50.81568,-113.5 113.5,-113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path565"/></g
      ><path
        d="m 1598,4483.5 c 0,-62.6841 50.8157,-113.5 113.5,-113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path567"/><path
        d="M 7756,10229 H 3152"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path569"/><path
        d="M 2842,10229 H 1711"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path571"/><path
        d="M 9197,10229 H 8066"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path573"/><g id="g575" transform="rotate(180,4598.75,5058.25)"
        ><path
          d="m -113.5,0 c 0,-62.68432 50.81568,-113.5 113.5,-113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path577"/></g
      ><g id="g579" transform="rotate(-90,5914,4202.5)"
        ><path
          d="m -113.5,0 c 0,-62.68432 50.81568,-113.5 113.5,-113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path581"/></g
      ><path
        d="m 9310,4483 v 114 5405 114"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path583"/><path
        d="M 1598,10116 V 10002 4597 4483"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path585"/><path
        d="M 9310,4597 H 1598"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path587"/><path
        d="m 8066,9078 v 924 h 1244"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path589"/><g
        id="g591"
        transform="matrix(0.86604,0.49997,-0.49997,0.86604,7911.5,9078.5)"
        ><path
          d="m 0,-154.5 c 55.1993,0 106.20527,29.4494 133.80339,77.25427"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path593"/></g
      ><g id="g595" transform="rotate(-90,8495,583.5)"
        ><path
          d="m 0,-154.5 c 55.1993,0 106.20527,29.4494 133.80339,77.25427"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path597"/></g
      ><path
        d="m 3152,9078 v 924 h 4604 v -924"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path599"/><g
        id="g601"
        transform="matrix(0.49997,-0.86604,0.86604,0.49997,2997.5,9078.5)"
        ><path
          d="m 154.5,0 c 0,55.1993 -29.4494,106.20527 -77.25427,133.80339"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path603"/></g
      ><g id="g605" transform="rotate(-90,6038,3040.5)"
        ><path
          d="m 0,-154.5 c 55.1993,0 106.20527,29.4494 133.80339,77.25427"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path607"/></g
      ><path
        d="M 1598,10002 H 2842 V 9078"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path609"/><path
        d="m 3152,10229 v -227"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path611"/><path
        d="m 2842,10002 v 227"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path613"/><path
        d="m 8066,10229 v -227"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path615"/><path
        d="m 7756,10002 v 227"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path617"/></g
  ></g>
</template>
<script>
export default {
  name: "PressureMa26Top",
};
</script>
